import { render, staticRenderFns } from "./toggle.vue?vue&type=template&id=a4510838&scoped=true"
import script from "./toggle.vue?vue&type=script&lang=js"
export * from "./toggle.vue?vue&type=script&lang=js"
import style0 from "./toggle.vue?vue&type=style&index=0&id=a4510838&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4510838",
  null
  
)

/* custom blocks */
import block0 from "~/locales/common/search/form/dates.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fazp%2F_work%2F1%2Fs%2Fcomponents%2Fsearch%2Fdates%2Ftoggle.vue&lang=yaml&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./toggle.vue?vue&type=custom&index=1&blockType=i18n&lang=yaml"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchDatesText: require('/azp/_work/1/s/components/search/dates/text.vue').default,BannerSeasonal: require('/azp/_work/1/s/components/banner/seasonal.vue').default,OverlayDrawer: require('/azp/_work/1/s/components/overlay-drawer.vue').default})
